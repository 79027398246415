import React from "react";
import { useApiClient } from "../../shared/customHooks";
import { useParams } from "react-router-dom";
import FormContainer from "../../components/form/FormContainer";
import { userForm } from "../../shared/formObjects/userForm";
import { Header } from "../../components/header/Header";
import { PageHeader } from "../../components/header/PageHeader";

export default function EditUser() {
  const api = useApiClient("UserManagement", { identity: true });

  const { id } = useParams<{ id: string }>();

  const headerText = [
    {
      class: "flex align-center mt-0",
      value: `${id ? "Edit" : "Add"} User`,
      icon: "view-app-icon mr-3 mt-0",
    },
  ];

  return (
    <>
      <Header />
      <PageHeader headerText={headerText} icon={"icon"} />

      <FormContainer apiClient={api} formBody={userForm} id={id} showInternalNotes={false} cancelPath=".." submitRoute=".." />
    </>
  );
}

import { Button } from "primereact/button";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */

const SignInSignOut = () => {
  const navigate = useNavigate();

  // button animation
  useEffect(() => {
    const element = document.getElementById("bg-animation");
    if (element) {
      element.classList.add("bg-animate");
    }
  }, []);

  return (
    <>
      <div className="" id="bg-animation">
        <div className="full-screen-container">
          <div className="image-container text-white">
            <div className="image-home"></div>
          </div>
          <div className="text-container">
            <h1 className="text-purple">Kindergarten Inclusion Support Program</h1>
            <p className="mx-5">
              The KIS Program Application Service is operated by Australian Healthcare Associates, on behalf of the Victorian Department of Education.
              Kindergartens can submit KIS application forms using the online submission facility. <br />
              <br />
              Further information about the KIS program, including application guidelines and forms, can be found on the{" "}
              <div className="inline-flex">
                <a
                  href="https://www.vic.gov.au/kindergarten-inclusion-children-disabilities"
                  target="_blank"
                  title="Link opens in a new tab"
                  rel="noreferrer">
                  {" "}
                  Department's website.
                </a>
                <span className="open-in-new-tab ml-2"></span>
              </div>
              <br /> <br />
              Click on the <strong>Submit Application</strong> button below to submit a Kindergarten Inclusion Support Program Application for a child
              enrolled at your kindergarten.{" "}
              <Button
                className="flex align-items-center flex-row pl-0 mt-1 bg-transparent border-none main-button"
                onClick={() => navigate("/externalRequest")}>
                {" "}
                <div className="text-purple font-bold">Submit Application</div>
                <div title="Submit Application" className="primary-button w-7rem mr-5 border-none login"></div>
              </Button>
            </p>
          </div>
          <div className="landing-footer p-0">
            <img src="/assets/icons/email.png" />
            <span className="mr-2">support@kisapplications.com.au </span> <span className="mx-2">|</span>
            <img src="/assets/icons/phone.png" /> <span>1300 564 149</span>
          </div>
        </div>
      </div>
    </>
  );
};

export const LandingPage = (props: { children: any }) => {
  return (
    <div>
      <SignInSignOut />
      {props.children}
    </div>
  );
};

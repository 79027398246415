import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { apiClient } from "../../shared/customHooks/hooks/useApiClient";
import { Dropdown } from "primereact/dropdown";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";

export interface rowColumn {
  label: string;
  column?: string;
  type?: "string" | "number" | "date" | "enum" | "boolean";
  enumType?: any; //enum in here for value matching
  allowSort?: boolean;
  sortOn?: string;
  isVisible?: boolean;
  isFilter?: boolean;
  filterElements?: { label: string; value: any }[];
  filterChange?: (event: any) => void; // New property for filter change handler
  body?: (rowData: any) => React.JSX.Element | any;
}

interface tableOptions {
  take?: number;
  sortDirection?: "asc" | "desc";
  searchColumns?: string[];
  skip?: number;
  sortColumn?: string;
  filter?: any;
}

interface tableProps {
  apiClient: apiClient;
  label?: string;
  columns: rowColumn[];
  tableOptions?: tableOptions;
  route?: string;
}

export default function Table({ apiClient, label, columns, tableOptions, route }: tableProps) {
  const [options, setOptions] = useState<tableOptions>({ take: 10, sortDirection: "desc", skip: 0, sortColumn: "DateCreated" });
  const [tableData, setTableData] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [search, setSearch] = useState("");
  const [filterCss, setFilterCss] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<any>({});

  const refreshTable = (newOptions: tableOptions, newSearch?: string) => {
    const payload = { ...newOptions, search: newSearch ?? search };
    apiClient.postData(payload, route ?? "all").then((res) => {
      setOptions(newOptions);
      setTableData(res.data.data);
      setTotalResults(res.data.totalResults);
    });
  };

  useEffect(() => {
    const searchColumns = columns.filter((c) => c.sortOn != null).map((c) => c.sortOn);
    const initOptions = { ...options, ...tableOptions };
    initOptions.searchColumns = searchColumns as string[];
    refreshTable(initOptions);
  }, []);

  const debouncedFetchSearchResults = useCallback(
    debounce(async (searchQuery: any) => {
      const o = { ...options };
      o.skip = 0;
      refreshTable(o, searchQuery);
    }, 1000),
    [options] // Add stable dependencies
  );
  const handleSearchChange = (e: any) => {
    const newQuery = e.target.value;
    setSearch(newQuery);
    if (newQuery.length >= 3 || newQuery.length == 0) {
      debouncedFetchSearchResults(newQuery);
    }
  };

  function sort(event: any) {
    const col = columns.find((x) => x.column == event.sortField);
    const o = { ...options };
    o.skip = 0;
    if (o.sortColumn != col?.sortOn) {
      o!.sortColumn = col?.sortOn;
    } else {
      o.sortDirection = o.sortDirection == "asc" ? "desc" : "asc";
    }
    refreshTable(o);
  }

  function onPage(event: any) {
    const newOptions = { ...options };
    newOptions.skip = event.first;
    newOptions.take = event.rows;
    refreshTable(newOptions);
  }

  const onFilterChange = (e: any, col: rowColumn) => {
    const columnKey = String(col.column);

    const o = { ...options };
    o.skip = 0;
    if (e.value == -1) {
      if (Object.prototype.hasOwnProperty.call(o.filter, columnKey)) {
        delete o.filter[columnKey];
      }
    } else {
      o.filter = { ...o.filter, [columnKey]: e.value != null ? e.value : "none" };
    }
    setSelectedStatus(o.filter);
    setFilterCss(true);
    refreshTable(o);
  };

  function Body(rowData: any, col: rowColumn) {
    //DO NOT POLUTE WITH SPECIFIC FUNCTIONALITY, if you cant make it generic, use the body callback
    if (col.body) {
      return col.body(rowData);
    }
    const result = col.column!.split(".").reduce((previous, current) => previous && previous[current], rowData);
    if (result == null) return;
    if (col.type === "date") {
      const dateValue = new Date(rowData[col.column ?? ""]);
      const formattedDate =
        ("0" + dateValue.getDate()).slice(-2) + "/" + ("0" + (dateValue.getMonth() + 1)).slice(-2) + "/" + dateValue.getFullYear();
      return formattedDate;
    }
    if (col.type == "enum") {
      const enumVal = col.enumType[result];
      const friendlyEnum = enumVal
        .split(/(?=[A-Z])/) // Split before each uppercase letter
        .join(" ");
      return friendlyEnum;
    } else {
      return result;
    }
  }

  return (
    <>
      <h1>{label}</h1>
      <div className="flex flex-row w-full mb-3">
        {" "}
        <IconField iconPosition="left" className="w-full">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText placeholder="Search" name="search" className="w-full" value={search} onChange={handleSearchChange} />
        </IconField>
      </div>
      <DataTable
        paginator
        lazy
        onSort={sort}
        value={tableData}
        totalRecords={totalResults}
        footer={"Total number of entries: " + totalResults}
        rows={options.take}
        onPage={onPage}
        first={options.skip}
        rowsPerPageOptions={[10, 25, 50]}
        emptyMessage="No entries found"
        filterDisplay="menu"
        className="mt-5"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
        tableStyle={{ minWidth: "50rem" }}>
        {columns.map((col) => (
          <Column
            key={col.column}
            field={col.column}
            header={col.label}
            sortable={col.allowSort}
            body={(rowData) => Body(rowData, col)}
            filter={col.isFilter}
            showClearButton={false}
            showApplyButton={false}
            showAddButton={false}
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            filterElement={
              col.isFilter != undefined &&
              col.isFilter && (
                <Dropdown
                  className={filterCss && col.isFilter ? "filterIcon" : "noFilter"}
                  options={Array.isArray(col.filterElements) ? col.filterElements : []}
                  onChange={(e: any) => onFilterChange(e, col)}
                  placeholder={`Select ${col.label}`}
                  value={col.column && selectedStatus[col.column]}
                />
              )
            }
          />
        ))}
      </DataTable>
    </>
  );
}

//FRONT END PAGINATED TABLE

import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { rowColumn } from "./Table";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";

interface frontEndTableProps {
  header: string;
  data: any[];
  columns: rowColumn[];
  TopRightContent?: React.FC<any>;
}
export default function FrontEndTable({ header, data, columns, TopRightContent }: frontEndTableProps) {
  const [searchedData, setSearchedData] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [first, setFirst] = useState(0); // starting index
  const [rows, setRows] = useState(10);
  useEffect(() => {
    setSearchedData(data);
  }, [data]);

  function Body(rowData: any, col: rowColumn) {
    //show view details
    if (col.body) {
      return col.body(rowData);
    }
    const result = col.column!.split(".").reduce((previous, current) => previous && previous[current], rowData);
    if (result == null) return;
    if (col.type === "date") {
      const dateValue = new Date(rowData[col.column ?? ""]);
      const formattedDate =
        ("0" + dateValue.getDate()).slice(-2) + "/" + ("0" + (dateValue.getMonth() + 1)).slice(-2) + "/" + dateValue.getFullYear();
      return formattedDate;
    }
    if (col.type === "boolean") {
      return result ? "Yes" : "No";
    }
    if (col.type == "enum") {
      const enumVal = col.enumType[result];
      const friendlyEnum = enumVal
        .split(/(?=[A-Z])/) // Split before each uppercase letter
        .join(" ");
      return friendlyEnum;
    } else {
      return result;
    }
  }

  const onGlobalFilterChange = (event: any) => {
    const value = event.target.value;
    const _data = [...data];

    const look = _data.filter((x) => {
      const res = columns.some((c) => {
        const colVal: string = c.column?.split(".").reduce((prev, curr) => (prev ? prev[curr] : undefined), x as any);
        if (colVal == null) return false;
        if (c.type === "enum") {
          const matches = Object.keys(c.enumType)
            .filter((x) =>
              x
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .trim()
                .toLowerCase()
                .includes(value.toLowerCase())
            )
            .map((x: any) => c.enumType[x]);

          const res = matches.includes(colVal);
          return res;
        } else if (c.type === "boolean") {
          return (colVal && "yes".includes(value.toLowerCase())) || (!colVal && "no".includes(value.toLowerCase()));
        } else {
          const res = colVal.toLowerCase().includes(value.toLowerCase());
          return res;
        }
      });
      return res;
    });
    setSearch(value);
    setSearchedData(look);
  };

  const onPage = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  return (
    <>
      <h2>{header}</h2>
      <div className="flex justify-content-between">
        <div className="flex w-full">
          {" "}
          <InputText placeholder="Search" name="search" className="w-3 pl-5" value={search} onChange={onGlobalFilterChange} />
          <span className="search-icon"></span>
        </div>
        <div>{TopRightContent != null && TopRightContent(null)}</div>
      </div>

      <DataTable
        value={searchedData}
        paginator
        first={first}
        rows={rows}
        onPage={onPage}
        footer={"Total number of entries: " + searchedData.length}
        rowsPerPageOptions={[10, 25, 50]}
        emptyMessage="No entries found"
        className="mt-5"
        tableStyle={{ minWidth: "50rem" }}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results">
        {columns.map((c, i) => (
          <Column key={i} field={c.column} header={c.label} sortable={c.allowSort} body={(rowData) => Body(rowData, c)}></Column>
        ))}
      </DataTable>
    </>
  );
}

import { Button } from "primereact/button";
import { FormGroup } from "../../../components/form";
import React from "react";

export const applicationFilesForm = (downloadAll: (props: any) => void): FormGroup => ({
  type: "formGroup",
  fields: [
    {
      type: "field",
      key: "advisory",
      fieldType: "advisory",
      label: "Application and Evidence Upload",
      props: {
        type: "mb-0",
      },
    },
    {
      type: "field",
      key: "advisory",
      fieldType: "advisory",
      props: {
        type: "warning m-0",
      },
      label: "Upload your application forms and all supporting information documents in the sections as indicated.",
    },

    {
      type: "component",
      body: (props: any) => (
        <Button type="button" className="form-button mt-0 download-button float-right mb-2" onClick={() => downloadAll(props)}>
          Download All
        </Button>
      ),
    },
    {
      type: "field",
      key: "applicationFormPartOne",
      fieldType: "file",
      label: "Application Form - Section 1",
      warn: "This section is for the KIS program application form - Section 1",
      expressionProps: {
        uuid: (context) => context.uuid,
      },
    },
    {
      type: "field",
      key: "applicationFormPartTwo",
      fieldType: "file",
      label: "Application Form - Section 2",
      warn: "This section is for the KIS program application form - Section 2",
      expressionProps: {
        uuid: (context) => context.uuid,
      },
    },
    {
      type: "field",
      key: "evidenceDocuments",
      fieldType: "file",
      label: "Evidence Documents",
      warn: "This section is for all the Evidence Documents being used to support the application",
      expressionProps: {
        uuid: (context) => context.uuid,
      },
    },
  ],
});

import React from "react";
import { rowColumn } from "../../components/table/Table";
import { RoleId } from "../enums/Role";
import { Link } from "react-router-dom";

const UsersTable: rowColumn[] = [
  {
    label: "Display Name",
    column: "displayName",
    type: "string",
    allowSort: true,
    isVisible: true,
  },
  {
    label: "Email",
    column: "userEmail",
    type: "string",
    allowSort: true,
    isVisible: true,
  },
  {
    label: "Role",
    column: "roleId",
    type: "enum",
    enumType: RoleId,
    isFilter: true,
    isVisible: true,
  },
  {
    label: "Active",
    column: "isActive",
    type: "boolean",
    isFilter: true,
    // body: (rowData) => <div className="flex justify-content-left">{rowData.isActive ? "Yes" : "No"}</div>,
    isVisible: true,
  },
  {
    label: "Actions",
    isVisible: true,
    body: (rowData: any) => (
      <div className="flex justify-content-left">
        <Link to={rowData?.id}>
          <span className="pi pi-user-edit mr-1"></span>Edit
        </Link>
      </div>
    ),
  },
];

export default UsersTable;

import { Menubar } from "primereact/menubar";
import "primeicons/primeicons.css";
import React, { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { MenuItem } from "primereact/menuitem";

export function Header() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const Logout = async () => {
    try {
      await instance.logoutRedirect();
    } catch (error) {
      console.error(error);
    }
  };

  const [items, setItems] = useState<MenuItem[]>([
    {
      label: "Home",
      url: "/home",
    },

    {
      label: "Applications",
      items: [
        {
          label: "Applications",
          url: "/applications",
        },
        {
          label: "My Applications",
          url: "/myapplications",
        },
      ],
    },
    {
      label: account?.name,
    },
    {
      label: "Logout",
      command: () => {
        Logout();
      },
    },
  ]);

  useEffect(() => {
    const newItems = [...items];
    newItems.forEach((i: any) => {
      isActive(i);
    });
    setItems(newItems);
  }, [location.pathname]);

  const isActive = (item: MenuItem) => {
    const route = location.pathname.split("/")[1];
    if (route === item.url?.replace(/\//g, "")) {
      item.className = "active-menu-item";
      return true;
    } else if (item.items != null && items.length > 0) {
      let subSelected = false;
      item.items.forEach((i: any) => {
        const isSelected = isActive(i);
        if (isSelected) {
          subSelected = true;
        }
      });
      if (subSelected) {
        item.className = "active-menu-item";
      }
    }
  };

  return (
    <>
      <div className="main-nav">
        <div className="flex justify-content-left kis-logo">
          <div className="flex text-blue font-bold justify-content-start text-left align-items-center flex-row">
            <div className="flex mt-1">
              <div className="header">
                <div className="text-purple text-3xl flex align-center ">
                  {" "}
                  <span className="kis-icon-purple"></span>Kindergarten Inclusion <br />
                  Support
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
        <Menubar model={items} className="mr-5 ml-1 bg-none border-none user-name pl-0 flex justify-content-end drop-down" />
      </div>
    </>
  );
}

import { FormGroup } from "../../components/form";
import * as Yup from "yup";

export const userForm: FormGroup = {
  type: "formGroup",
  fields: [
    {
      type: "field",
      key: "userEmail",
      fieldType: "input",
      label: "Email",
      validation: Yup.string()
        .required("Email is required")
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email address"),
      expressionProps: {
        disabled: (context, values) => {
          return values.dateCreated != null;
        },
      },
    },
    {
      type: "field",
      key: "displayName",
      fieldType: "input",
      label: "Display name",
      validation: Yup.string().required("Display name is required"),
      hideExpression: (context, values) => {
        return values.dateCreated == null;
      },
    },
    {
      type: "field",
      key: "roleId",
      fieldType: "dropdown",
      label: "Role",
      validation: Yup.number().required("Role is required"),
      props: {
        options: [
          {
            label: "Root",
            value: 1,
          },
          {
            label: "Assessor",
            value: 2,
          },
          {
            label: "Team Lead",
            value: 3,
          },
        ],
      },
    },
    {
      type: "field",
      key: "inviteRedirectUrl",
      fieldType: "input",
      defaultValue: import.meta.env.VITE_POSTLOGOUTREDIRECTURI,
      label: "Display name",
      validation: Yup.string().required("Display name is required"),
      hideExpression: () => {
        return true;
      },
    },
  ],
};

import React, { useEffect, useRef } from "react";
import "./App.css";
import { Routes, Route, Outlet } from "react-router";
import { Modal, ModalRef } from "./components/modal/modal";

import { Home } from "./pages/Home";
import LandingRouter from "./pages/LandingRouter";
import ScrollToTopButton from "./components/scrollToTop/ScrollToTop";
import { AuthRoute } from "./shared/auth/AuthRoute";
import { Role } from "./shared/enums/Role";
import axios from "axios";
import { ToasterComponent } from "./components/toaster/toaster";
// import { useApiClient } from "./shared/customHooks";
//import ExternalLink from "./components/externalLink/externalLink";
import ExternalApplicationEvidence from "./pages/external/ExternalApplicationEvidence";
import ExternalApplicationRequest from "./pages/external/ExternalApplicationRequest";
import ApplicationsList from "./pages/appllicationPages/ApplicationsList";
import ApplicationView from "./pages/appllicationPages/ApplicationView";
import { Footer } from "./components/footer/Footer";
import { LoginPage } from "./pages/Login";
import LogoutPage from "./pages/Logout";
import ApplicationFilesPage from "./pages/appllicationPages/ApplicationFilesPage";
import ChildPage from "./pages/appllicationPages/ChildPage";
import KindergartenPage from "./pages/appllicationPages/KindergartenPage";
import ApplicationTypePage from "./pages/appllicationPages/ApplicationTypePage";
import { SpinnerComponent } from "./components/spinner/SpinnerComponent";
import AssignAssessorPage from "./pages/appllicationPages/AssignAssessorPage";
import { ApplicationContextProvider } from "./components/applicationComponents/context/applicationContext";
import MyApplicationsList from "./pages/appllicationPages/MyApplicationsList";
import ManageUsers from "./pages/useManagment/ManageUsers";
import EditUser from "./pages/useManagment/EditUser";

function App() {
  axios.defaults.headers.post["Content-Type"] = "application/json";
  const modalRef = useRef<ModalRef>(null);

  useEffect(() => {}, []);

  return (
    <>
      <Modal cancelAction={() => console.log("closing")} confirmAction={() => console.log("confirm")} ref={modalRef} />
      <div className="app-container">
        <Routes>
          <Route>
            <Route path="/*" element={<LandingRouter />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="logout" element={<LogoutPage />} />
            <Route
              path="home"
              element={
                <AuthRoute roles={[Role.assessor, Role.teamLead, Role.root]}>
                  <Home />
                </AuthRoute>
              }
            />
            <Route
              path="applications"
              element={
                <AuthRoute roles={[Role.assessor, Role.teamLead, Role.root]}>
                  <Outlet />
                </AuthRoute>
              }>
              <Route index element={<ApplicationsList />} />
              <Route
                path=":id"
                element={
                  <ApplicationContextProvider>
                    <Outlet />
                  </ApplicationContextProvider>
                }>
                <Route index element={<ApplicationView />} />
                <Route path="applicationfiles" element={<ApplicationFilesPage />} />
                <Route path="child" element={<ChildPage />} />
                <Route path="assignassessor" element={<AssignAssessorPage />} />
                <Route path="applicationtype" element={<ApplicationTypePage />} />
                <Route path="kindergarten" element={<KindergartenPage />} />
              </Route>
            </Route>
            <Route
              path="myapplications"
              element={
                <AuthRoute roles={[Role.assessor, Role.teamLead, Role.root]}>
                  <Outlet />
                </AuthRoute>
              }>
              <Route index element={<MyApplicationsList />} />
              <Route
                path=":id"
                element={
                  <ApplicationContextProvider>
                    <Outlet />
                  </ApplicationContextProvider>
                }>
                <Route index element={<ApplicationView />} />
                <Route path="applicationfiles" element={<ApplicationFilesPage />} />
                <Route path="child" element={<ChildPage />} />
                <Route path="assignassessor" element={<AssignAssessorPage />} />
                <Route path="applicationtype" element={<ApplicationTypePage />} />
                <Route path="kindergarten" element={<KindergartenPage />} />
              </Route>
            </Route>
            <Route
              path="manageUsers"
              element={
                <AuthRoute roles={[Role.teamLead, Role.root]}>
                  <Outlet />
                </AuthRoute>
              }>
              <Route index element={<ManageUsers />} />
              <Route path="new" element={<EditUser />} />
              <Route path=":id" element={<EditUser />} />
            </Route>
            <Route path="applicationevidence" element={<ExternalApplicationEvidence />} />
            <Route
              path="externalRequest"
              element={
                <>
                  <ExternalApplicationRequest />
                </>
              }
            />
          </Route>
        </Routes>
        <Footer />
        <ToasterComponent />
        <SpinnerComponent />
      </div>
      <ScrollToTopButton />
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import BaseApplicationComponent from "../baseApplicationComponent/BaseApplicationComponent";
import { useApiClient } from "../../../shared/customHooks";
import { downloadAll } from "../../form/scanner/ScannerService";
import { useApplicationContext } from "../context/applicationContext";
import { applicationFilesForm } from "../../../shared/formObjects/application/applicationFilesForm";
import { format } from "date-fns/format";

export default function ApplicationFilesComponent({ disabled, data }: any) {
  const api = useApiClient("ApplicationFiles");
  const [formContext, setFormContext] = useState<any>({});
  const { id, applicationBase } = useApplicationContext();

  useEffect(() => {
    setFormContext({ uuid: applicationBase.applicationFiles.fileUuid });
  }, []);

  function downloadAllFiles(obj: any) {
    const allFiles = getAllFiles(obj);
    const requestData = allFiles.map((file) => ({
      files: file,
    }));

    downloadAll(requestData).then((response: any) => {
      if (response && response.data != null) {
        // Create a Blob from the response data (the zip file)
        const blob = response.data;

        // Create an object URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary <a> element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "files_" + id + "_" + format(new Date(), "ddMMyyyyHHmmss") + ".zip"; // Name of the downloaded file
        link.click(); // Trigger the download

        // Clean up the URL object to free memory
        window.URL.revokeObjectURL(url);
      }
    });
  }

  const getAllFiles = (obj: any) => {
    let files: any[] = [];

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];

        if (Array.isArray(value)) {
          value.forEach((item) => {
            if ("blobKey" in item) {
              files.push("application-evidence/" + item.blobKey);
            }
          });
        } else if (typeof value === "object") {
          files = files.concat(getAllFiles(value));
        }
      }
    }

    return files;
  };

  return (
    <>
      <div className="flex flex-column align-items-start w-full height-calc">
        <BaseApplicationComponent
          formBody={applicationFilesForm(downloadAllFiles)}
          formContext={formContext}
          headerLabel="Application and Evidence Upload"
          editPath="applicationfiles"
          apiClient={api}
          disabled={disabled}
          data={data}
        />
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import FrontEndTable from "../../components/table/FrontEndTable";
import { useApiClient } from "../../shared/customHooks";
import UsersTable from "../../shared/tableObjects/usersTable";
import { Header } from "../../components/header/Header";
import { PageHeader } from "../../components/header/PageHeader";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

export default function ManageUsers() {
  const [users, setUsers] = useState<any[]>([]);
  const { fetchData } = useApiClient("UserManagement", { identity: true });
  useEffect(() => {
    fetchData("GetAllUsers").then((res) => {
      setUsers(res.data);
    });
  }, []);
  const headerText = [
    {
      class: "flex align-center mt-0",
      value: "Manage Users",
      icon: "view-app-icon mr-3 mt-0",
    },
  ];
  return (
    <>
      <Header />
      <PageHeader headerText={headerText} icon={"icon"} />
      <FrontEndTable
        columns={UsersTable}
        data={users}
        header=""
        TopRightContent={() => (
          <Link to="new">
            <Button label="Add User" className="form-button mt-0 " />
          </Link>
        )}
      />
    </>
  );
}

export enum Role {
  root = "root",
  assessor = "assessor",
  teamLead = "team lead",
}

export enum RoleId {
  Root = 1,
  Assessor = 2,
  TeamLead = 3,
}

import { useEffect, useState } from "react";
import React from "react";
import { useApiClient } from "../../../shared/customHooks";
import { FormGroup } from "../../form";
import { assignAssessorForm } from "../../../shared/formObjects/application/assignAssessorForm";
import BaseApplicationComponent from "../baseApplicationComponent/BaseApplicationComponent";

export default function AssignAssessorComponent({ disabled, data }: any) {
  const [form, setForm] = useState<FormGroup>(assignAssessorForm([]));
  const { fetchData } = useApiClient("UserManagement", { identity: true });
  const formApi = useApiClient("AssignAssessor");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchData("GetUsersByRoles/2,3").then((res) => {
      const options = (res.data as any[])
        .map((user) => ({
          label: user.displayName,
          value: user.userEmail,
        }))
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      setForm(assignAssessorForm(options));
      setLoaded(true);
    });
  }, []);

  return (
    <>
      {loaded && (
        <BaseApplicationComponent
          formBody={form}
          headerLabel="Assign an Assessor"
          editPath="assignassessor"
          apiClient={formApi}
          disabled={disabled}
          data={data}
        />
      )}
    </>
  );
}

import { InputText } from "primereact/inputtext";
import React, { useEffect, useMemo, useState } from "react";
import { useApiClient } from "../../shared/customHooks";
import { Button } from "primereact/button";
import { eventEmitter } from "../../shared/event";
import { ToastInfo } from "../toaster/toaster";
import ComponentHeader, { ComponentHeadeProps } from "../componentHeader/componentHeader";

interface externalLink {
  id: number;
  linkId: string;
  applicationId: number;
  expirationDate: Date;
  disabled: boolean;
}

interface linkProps {
  linkLabel: string;
  applicationId: string;
}

function ExternalLink({ applicationId, linkLabel }: linkProps) {
  const [link, setLink] = useState<externalLink | null>(null);
  const [copied, setCopied] = useState(false);
  const [headerData, setHeaderData] = useState<ComponentHeadeProps>({});
  const { fetchData, postData, putData } = useApiClient(`ExternalLink`);

  useEffect(() => {
    if (applicationId != "") {
      fetchData(applicationId).then((res) => {
        if (res.data === "") return;

        setLink(res.data);
        const headerData: ComponentHeadeProps = {
          dateCreated: res?.data.dateCreated,
          userCreated: res?.data.userCreated,
        };
        setHeaderData(headerData);
      });
    }
  }, [applicationId]);

  const url = useMemo(() => {
    return link ? location.protocol + "//" + location.host + "/applicationevidence?id=" + link.linkId : "";
  }, [link]);

  function copyLink() {
    navigator.clipboard.writeText(url).then(() => {
      const toastData: ToastInfo = { title: "Success", message: "Copied to clip-board", severity: "success" };
      eventEmitter.emit("tstr", toastData);
      setCopied(true);
    });
  }

  function generateLink() {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate);
    expirationDate.setDate(currentDate.getDate() + 7);
    expirationDate.setHours(23, 59, 59);
    const link = {
      applicationId,
    };
    postData(link).then((res) => {
      setLink(res.data);
      setLink(res.data);
      const headerData: ComponentHeadeProps = {
        dateCreated: res?.data.dateCreated,
        userCreated: res?.data.userCreated,
      };
      setHeaderData(headerData);
    });
  }

  function resetLink() {
    link!.disabled = true;
    putData(link, link!.id.toString()).then(() => {
      setLink(null);
      setCopied(false);
    });
  }

  return (
    <>
      <ComponentHeader {...headerData} label="Generate Link" />
      <div className="w-full border-2 border-round p-5">
        <p className="text-left">{linkLabel}</p>
        <div className="flex justify-content-start align-items-center w-full">
          <InputText value={url} disabled className="mr-5" />
          {link != null ? (
            <div className="flex justify-content-start align-items-center">
              <Button onClick={() => copyLink()} label={copied ? "Copied" : "Copy"} className="copy-button mt-0 mr-3" />
              <Button onClick={() => resetLink()} label="Re-set" className="reset-button mt-0" />
            </div>
          ) : (
            <Button onClick={() => generateLink()} label="Generate" className="form-button mt-0 gen-button" />
          )}
        </div>
      </div>
    </>
  );
}

export default ExternalLink;

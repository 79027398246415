import React from "react";
import ReactDOM from "react-dom/client";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css
import "./styles/style.scss";
import { PrimeReactProvider } from "primereact/api";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./authConfig";
import App from "./App";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppInsightsErrorBoundary, ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    connectionString: `${import.meta.env.VITE_INSIGHTS_INSTRUMENTATION_KEY}`,
  },
});
appInsights.loadAppInsights();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <BrowserRouter>
        <AppInsightsErrorBoundary onError={() => <div></div>} appInsights={reactPlugin}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </AppInsightsErrorBoundary>
      </BrowserRouter>
    </PrimeReactProvider>
  </React.StrictMode>
);
